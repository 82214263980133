import { usePage } from '@inertiajs/vue3';
import User from '../Models/User';

export function useCurrentUser() {
    const user = usePage().props.auth.user;

    if (!user) {
        return null;
    }

    return new User(user);
}
